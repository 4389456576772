jQuery(document).ready(function ($) {
  // image alt
  $('img').each(function () {
    var img = $(this);
    var src = $(this).attr('src');
    var filename = src.split('/').reverse()[0].replace(/\.[^/.]+$/, '');
    if (img.parent('a').length != 0) {
      if (img.not('[alt]')) {
        img.attr('alt', 'image ' + filename);
      }
    } else {
      if (img.not('[alt]')) {
        img.attr('alt', '');
      }
    }
  });

  // responsive tables
  $('main table').each(function () {
    if (!$(this).parents('.search-results').length) {
      if (!$(this).hasClass('tablesaw')) {
        $(this).addClass('tablesaw').attr('data-tablesaw-mode', 'stack');
      }
    }
  });
  $('main table th').css('width', 'auto').css('height', 'auto');
  $('main table td').css('width', 'auto').css('height', 'auto');

  // breadcrumbs
  $('ul.breadcrumb').prepend('<li><a href="/">Home</a></li>');
});


// tablesaw
(function (win) {
  "use strict";

  var $;
  if ('shoestring' in win) {
    $ = win.shoestring;
  } else if ('jQuery' in win) {
    $ = win.jQuery;
  } else {
    throw new Error("tablesaw: DOM library not found.");
  }

  if ("addEventListener" in document) {
    document.addEventListener("DOMContentLoaded", function () {
      $(document).trigger("enhance.tablesaw");
    });
  }

})(typeof window !== "undefined" ? window : this);